import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}>{children}</span>
)

/**
 */
export const EntriesContainer = ({ children }) => {
  return <div className={styles.EntriesContainer}>{children}</div>
}

/**
 */
export const EntryContainer = ({ children }) => (
  <div className={styles.EntryContainer}>{children}</div>
)

/**
 */
export const FieldsContainer = ({ children }) => (
  <div className={styles.FieldsContainer}>{children}</div>
)

/**
 */
export const FieldContainer = ({ children }) => <>{children}</>

/**
 */
export const FieldLabel = ({ children }) =>
  //<div className={styles.FieldLabel}>{children}</div>
  null

/**
 */
export const FieldValue = ({ children }) => (
  <div className={styles.FieldValue}>{children}</div>
)

/**
 */
export const CreateEntryIcon = () => (
  <Icons.CreateNew className={styles.CreateEntry_Icon} />
)
