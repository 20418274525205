import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const CreateNew = translated('app.project')('AddressList.CreateNew')

/**
 */
export const Title = translated('app.project')('AddressList.Title')
