import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = () => (
  <Layout.Wrapper>
    <Layout.Header>
      <Containers.Title />
    </Layout.Header>
    <Layout.Body>
      <Containers.Entries />
    </Layout.Body>
    <Layout.Footer></Layout.Footer>
  </Layout.Wrapper>
)
