import React from 'react'

import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
const withFieldComponents = (fieldName, Component) => ({
  components: {
    Container = Styled.FieldContainer,
    Label = Styled.FieldLabel,
    Value = Styled.FieldValue,
  } = {},
  ...props
}) => (
  <Container>
    <Label>{fieldName}</Label>
    <Value>
      <Component {...props} />
    </Value>
  </Container>
)

/**
 */
export const Address = withFieldComponents('Address', () => (
  <div className={styles.Address}>
    <ProjectDestination.Scope.Entry.Properties.Address.StateOrPrefecture />
    <ProjectDestination.Scope.Entry.Properties.Address.City />
    <ProjectDestination.Scope.Entry.Properties.Address.Street />
    <ProjectDestination.Scope.Entry.Properties.Address.Building />
  </div>
))

/**
 */
export const PostalCode = withFieldComponents('PostalCode', () => (
  <ProjectDestination.Scope.Entry.Properties.Address.PostalCode />
))

/**
 */
export const PhoneNumber = withFieldComponents('PhoneNumber', () => (
  <ProjectDestination.Scope.Entry.Properties.Address.PhoneNumber
    className={styles.PhoneNumber}
  />
))

/**
 */
export const CompanyName = withFieldComponents('CompanyName', () => (
  <ProjectDestination.Scope.Entry.Properties.ToCompany.CompanyName
    className={styles.CompanyName}
  />
))

export const DepartmentName = withFieldComponents('DepartmentName', () => (
  <ProjectDestination.Scope.Entry.Properties.ToCompany.DepartmentName
    className={styles.DepartmentName}
  />
))

export const OperatorName = withFieldComponents('OperatorName', () => (
  <>
    <ProjectDestination.Scope.Entry.Properties.ToCompany.OperatorLastName
      className={styles.OperatorName}
    />
    <ProjectDestination.Scope.Entry.Properties.ToCompany.OperatorFirstName
      className={styles.OperatorName}
    />
  </>
))

export const OperatorNamePhonetic = withFieldComponents(
  'OperatorNamePhonetic',
  () => (
    <>
      <ProjectDestination.Scope.Entry.Properties.ToCompany.OperatorLastNamePhonetic />
      <ProjectDestination.Scope.Entry.Properties.ToCompany.OperatorFirstNamePhonetic />
    </>
  ),
)
