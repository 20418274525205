import React from 'react'

import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as Partial from 'partials/AddressListPage'

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
const Page = ({ id }) => {
  return (
    <ProjectDestination.Remote.Queries.Entries.Loader projectId={id}>
      <Partial.Default />
    </ProjectDestination.Remote.Queries.Entries.Loader>
  )
}

export default Page
