import React from 'react'

import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as FieldComponents from './Fields'
import * as Styled from './Styled'
import * as Literals from './Literals'

import * as styles from './styles.module.scss'

/**
 */
export const Title = () => {
  return (
    <Styled.Title>
      <Literals.Title />
    </Styled.Title>
  )
}

/**
 */
const LinkToCreateEntry = () => (
  <ProjectLinks.ToCreateAddress className={styles.CreateEntry}>
    <Styled.CreateEntryIcon />
    <Literals.CreateNew />
  </ProjectLinks.ToCreateAddress>
)

/**
 */
const CreateEntry = () => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  if (!hasPermission(Permissions.DestinationsWrite)) {
    return null
  }

  return (
    <Styled.EntryContainer>
      <LinkToCreateEntry />
    </Styled.EntryContainer>
  )
}

/**
 */
export const Entries = () => (
  <Styled.EntriesContainer>
    <CreateEntry />
    <ProjectDestination.Scope.Entries.Each>
      <Entry />
    </ProjectDestination.Scope.Entries.Each>
  </Styled.EntriesContainer>
)

/**
 */
const Entry = () => (
  <Styled.EntryContainer>
    <Fields />
  </Styled.EntryContainer>
)

/**
 */
const Fields = () => (
  <Styled.FieldsContainer>
    <FieldComponents.CompanyName />
    <FieldComponents.PostalCode />
    <FieldComponents.Address />
    <FieldComponents.DepartmentName />
    <FieldComponents.OperatorName />
    <FieldComponents.OperatorNamePhonetic />
    <FieldComponents.PhoneNumber />
  </Styled.FieldsContainer>
)
