// extracted by mini-css-extract-plugin
export var Address = "styles-module--Address--+E+sP";
export var CompanyName = "styles-module--CompanyName--6oAGc";
export var CreateEntry = "styles-module--CreateEntry--nVTUp";
export var CreateEntry_Icon = "styles-module--CreateEntry_Icon--RUUsT";
export var DepartmentName = "styles-module--DepartmentName--WAbOb";
export var EntriesContainer = "styles-module--EntriesContainer--hYtxx";
export var EntryContainer = "styles-module--EntryContainer--Vuvf-";
export var FieldValue = "styles-module--FieldValue--44+Sb";
export var FieldsContainer = "styles-module--FieldsContainer--lRZSn";
export var Footer = "styles-module--Footer--LW4wN";
export var Header = "styles-module--Header--rz8-f";
export var OperatorName = "styles-module--OperatorName--D+8Pf";
export var PhoneNumber = "styles-module--PhoneNumber--YUtQa";
export var Title = "styles-module--Title---p+C6";